import './SettingsDialog.css';
import { Fragment, useState, useEffect } from 'react';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { GetCampaignDefaultSettings, UpdateCampaignDefaultSettings } from '../../../Services';
import { toast } from 'react-toastify';
import { copyToClipboard, createGuid } from '../../../utils/Common';

const SettingsDialog = ({ onClose }) => {
	const [ settingsData, setSettingsData ] = useState({});
	const [ categoryName, setCategoryName ] = useState();
	const [ websiteUrl, setWebsiteUrl ] = useState();
	const [ citationCommandText, setCitationCommandText ] = useState();
	const [ contentCommandText, setContentCommandText ] = useState();
	const [ currentPanel, setCurrentPanel ] = useState('prompts');
	const [ currentReportSettingsPanel, setCurrentReportSettingsPanel ] = useState('powerWebsites');
	const [ currentPowerCommandsPanel, setCurrentPowerCommandsPanel ] = useState('powerCitationsCommands');
	const [ showCategoryPanel, setShowCategoryPanel ] = useState(false);
	const [ showWebsitePanel, setShowWebsitePanel ] = useState(false);
	const [ showCitationCommandPanel, setShowCitationCommandPanel ] = useState(false);
	const [ showContentCommandPanel, setShowContentCommandPanel ] = useState(false);
	const [ editingCategory, setEditingCategory ] = useState();
	const [ editingWebsite, setEditingWebsite ] = useState();
	const [ editingCitationCommand, setEditingCitationCommand ] = useState();
	const [ editingContentCommand, setEditingContentCommand ] = useState();
	const [ selectedWebsiteGuids, setSelectedWebsiteGuids ] = useState([]);
	const [ websiteIsCitations, setWebsiteIsCitations ] = useState(true);
	const [ websiteIsContent, setWebsiteIsContent ] = useState(true);
	const [ citationsPlaceholders, setCitationsPlaceholders ] = useState([
		'{CitationUrl}',
		'{BusinessName}',
		'{BusinessUrl}',
		'{Address}',
		'{Phone}'
	]);
	const [ contentPlaceholders, setContentPlaceholders ] = useState([
		'{ContentUrl}',
		'{BusinessName}',
		'{BusinessUrl}',
		'{Address}',
		'{Phone}',
		'{Keyword}'
	]);

	useEffect(() => {
		getDefaultSettings();
	}, []);

	const getDefaultSettings = async () => {
		let response = await GetCampaignDefaultSettings();

		if (response && response.data) {
			setSettingsData(response.data);
		}
	};

	const handleSettingsChange = async (e) => {
		let name = e.target.name;
		let value = e.target.value;
		let checked = e.target.checked;

		if (name.indexOf('chkWebsite_') > -1) {
			let websiteGuid = name.replace('chkWebsite_', '');
			let tempSelectedWebsiteGuids = selectedWebsiteGuids || [];

			if (checked) {
				if (!tempSelectedWebsiteGuids.includes(websiteGuid)) {
					tempSelectedWebsiteGuids.push(websiteGuid);
				}
			} else {
				if (tempSelectedWebsiteGuids.includes(websiteGuid)) {
					tempSelectedWebsiteGuids = tempSelectedWebsiteGuids.filter((x) => x !== websiteGuid);
				}
			}

			setSelectedWebsiteGuids(tempSelectedWebsiteGuids);
		} else {
			setSettingsData({
				...settingsData,
				[name] : e.target.type === 'checkbox' ? checked : value
			});
		}
	};

	const handleCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleSaveDefaultSettings = async (data, closeDialog = false) => {
		let response = await UpdateCampaignDefaultSettings(data);

		if (response) {
			if (closeDialog) {
				handleCloseDialog();
				toast.success('Default settings updated successfully.');
			}
		} else {
			toast.error(response.message);
		}
	};

	//category -------------------------
	const handleAddNewCategory = () => {
		setCategoryName();
		setSelectedWebsiteGuids([]);
		setShowCategoryPanel(true);
	};

	const handleSaveCategory = async () => {
		if (!categoryName || categoryName.trim() === '') {
			toast.error('Enter category name');
			return;
		}

		let tempSettingsData = { ...settingsData };

		if (!tempSettingsData.industryCategories) {
			tempSettingsData.industryCategories = [];
		}

		//check if category already exists
		if (!editingCategory) {
			if (
				tempSettingsData.industryCategories &&
				tempSettingsData.industryCategories.length > 0 &&
				tempSettingsData.industryCategories.some((x) => x.categoryName === categoryName)
			) {
				toast.error('Category already exists');
				return;
			}
		}

		let categoryObj = {
			categoryName : categoryName,
			websites     : selectedWebsiteGuids
		};

		if (editingCategory) {
			let tempEditingCategory = tempSettingsData.industryCategories.find((x) => x === editingCategory);

			if (tempEditingCategory) {
				let tempEditingCategoryGuid = tempEditingCategory.categoryGuid;

				//remove old category first, keep guid
				tempSettingsData.industryCategories = tempSettingsData.industryCategories.filter(
					(x) => x.categoryGuid !== tempEditingCategory.categoryGuid
				);

				tempSettingsData.industryCategories.push({
					...categoryObj,
					categoryGuid : tempEditingCategoryGuid
				});
			}
		} else {
			tempSettingsData.industryCategories.push({
				...categoryObj,
				categoryGuid : createGuid()
			});
		}

		await handleSaveDefaultSettings(tempSettingsData);
		await getDefaultSettings();

		setEditingCategory();
		setShowCategoryPanel(false);
	};

	const handleEditCategory = (category) => {
		if (settingsData && settingsData.industryCategories) {
			let tempEditingCategory = settingsData.industryCategories.find((x) => x === category);

			if (tempEditingCategory) {
				setCategoryName(tempEditingCategory.categoryName);
				setSelectedWebsiteGuids(tempEditingCategory.websites || []);

				setEditingCategory(tempEditingCategory);
				setShowCategoryPanel(true);
			}
		}
	};

	const handleCancelSaveCategory = () => {
		setEditingCategory();
		setShowCategoryPanel(false);
		setSelectedWebsiteGuids([]);
	};

	const handleDeleteCategory = async (category) => {
		if (window.confirm('Are you sure that you want to delete category "' + category.categoryName + '"?')) {
			if (settingsData && settingsData.industryCategories) {
				let tempSettingsData = { ...settingsData };

				let tempDeletingCategory = tempSettingsData.industryCategories.find((x) => x === category);

				if (tempDeletingCategory) {
					tempSettingsData.industryCategories = tempSettingsData.industryCategories.filter(
						(x) => x !== category
					);

					await handleSaveDefaultSettings(tempSettingsData);
					await getDefaultSettings();
				}
			}
		}
	};

	//websites --------------------------
	const handleAddNewWebsite = () => {
		setWebsiteUrl();
		setWebsiteIsCitations(true);
		setWebsiteIsContent(true);
		setShowWebsitePanel(true);
	};

	const handleSaveWebsite = async () => {
		if (!websiteUrl || websiteUrl.trim() === '') {
			toast.error('Enter website url');
			return;
		}

		let tempSettingsData = { ...settingsData };

		if (!tempSettingsData.powerWebsites) {
			tempSettingsData.powerWebsites = [];
		}

		//check if website already exists
		if (!editingWebsite) {
			if (
				tempSettingsData.powerWebsites &&
				tempSettingsData.powerWebsites.length > 0 &&
				tempSettingsData.powerWebsites.some((x) => x.websiteUrl === websiteUrl)
			) {
				toast.error('Website already exists');
				return;
			}
		}

		let websiteObj = {
			websiteUrl         : websiteUrl.replace(/\/$/, ''),
			websiteIsCitations : websiteIsCitations,
			websiteIsContent   : websiteIsContent
		};

		if (editingWebsite) {
			let tempEditingWebsite = tempSettingsData.powerWebsites.find((x) => x === editingWebsite);

			if (tempEditingWebsite) {
				let tempEditingWebsiteGuid = tempEditingWebsite.websiteGuid;

				//remove old website first, keep guid
				tempSettingsData.powerWebsites = tempSettingsData.powerWebsites.filter(
					(x) => x.websiteGuid !== tempEditingWebsite.websiteGuid
				);

				tempSettingsData.powerWebsites.push({
					...websiteObj,
					websiteGuid : tempEditingWebsiteGuid
				});
			}
		} else {
			tempSettingsData.powerWebsites.push({
				...websiteObj,
				websiteGuid : createGuid()
			});
		}

		await handleSaveDefaultSettings(tempSettingsData);
		await getDefaultSettings();

		setEditingWebsite();
		setShowWebsitePanel(false);
	};

	const handleEditWebsite = (website) => {
		if (settingsData && settingsData.powerWebsites) {
			let tempEditingWebsite = settingsData.powerWebsites.find((x) => x === website);

			if (tempEditingWebsite) {
				setWebsiteUrl(tempEditingWebsite.websiteUrl);
				setWebsiteIsCitations(tempEditingWebsite.websiteIsCitations);
				setWebsiteIsContent(tempEditingWebsite.websiteIsContent);

				setEditingWebsite(website);
				setShowWebsitePanel(true);
			}
		}
	};

	const handleCancelSaveWebsite = () => {
		setEditingWebsite();
		setShowWebsitePanel(false);
	};

	const handleDeleteWebsite = async (website) => {
		if (window.confirm('Are you sure that you want to delete website "' + website.websiteUrl + '"?')) {
			if (settingsData && settingsData.powerWebsites) {
				let tempSettingsData = { ...settingsData };

				let tempDeletingWebsite = tempSettingsData.powerWebsites.find((x) => x === website);

				if (tempDeletingWebsite) {
					tempSettingsData.powerWebsites = tempSettingsData.powerWebsites.filter((x) => x !== website);

					await handleSaveDefaultSettings(tempSettingsData);
					await getDefaultSettings();
				}
			}
		}
	};

	//citations commands --------------------
	const handleAddNewCitationCommand = () => {
		setCitationCommandText();
		setShowCitationCommandPanel(true);
	};

	const handleSaveCitationCommand = async () => {
		if (!citationCommandText || citationCommandText.trim() === '') {
			toast.error('Enter citation command');
			return;
		}

		let tempSettingsData = { ...settingsData };

		if (!tempSettingsData.powerCitationsCommands) {
			tempSettingsData.powerCitationsCommands = [];
		}

		//check if citation already exists
		if (!editingCitationCommand) {
			if (
				tempSettingsData.powerCitationsCommands &&
				tempSettingsData.powerCitationsCommands.length > 0 &&
				tempSettingsData.powerCitationsCommands.some((x) => x.commandText === citationCommandText)
			) {
				toast.error('Citation command already exists');
				return;
			}
		}

		let citationObj = {
			commandText : citationCommandText
		};

		if (editingCitationCommand) {
			let tempEditingCitationCommand = tempSettingsData.powerCitationsCommands.find(
				(x) => x === editingCitationCommand
			);

			if (tempEditingCitationCommand) {
				let tempEditingCitationCommandGuid = tempEditingCitationCommand.commandGuid;

				//remove old Citation first, keep guid
				tempSettingsData.powerCitationsCommands = tempSettingsData.powerCitationsCommands.filter(
					(x) => x.commandGuid !== tempEditingCitationCommand.commandGuid
				);

				tempSettingsData.powerCitationsCommands.push({
					...citationObj,
					commandGuid : tempEditingCitationCommandGuid
				});
			}
		} else {
			tempSettingsData.powerCitationsCommands.push({
				...citationObj,
				commandGuid : createGuid()
			});
		}

		await handleSaveDefaultSettings(tempSettingsData);
		await getDefaultSettings();

		setEditingCitationCommand();
		setShowCitationCommandPanel(false);
	};

	const handleEditCitationCommand = (citation) => {
		if (settingsData && settingsData.powerCitationsCommands) {
			let tempEditingCitationCommand = settingsData.powerCitationsCommands.find((x) => x === citation);

			if (tempEditingCitationCommand) {
				setCitationCommandText(tempEditingCitationCommand.commandText);

				setEditingCitationCommand(citation);
				setShowCitationCommandPanel(true);
			}
		}
	};

	const handleCancelSaveCitationCommand = () => {
		setEditingCitationCommand();
		setShowCitationCommandPanel(false);
	};

	const handleDeleteCitationCommand = async (citation) => {
		if (window.confirm('Are you sure that you want to delete citation "' + citation.commandText + '"?')) {
			if (settingsData && settingsData.powerCitationsCommands) {
				let tempSettingsData = { ...settingsData };

				let tempDeletingCitation = tempSettingsData.powerCitationsCommands.find((x) => x === citation);

				if (tempDeletingCitation) {
					tempSettingsData.powerCitationsCommands = tempSettingsData.powerCitationsCommands.filter(
						(x) => x !== citation
					);

					await handleSaveDefaultSettings(tempSettingsData);
					await getDefaultSettings();
				}
			}
		}
	};

	//content commands --------------------
	const handleAddNewContentCommand = () => {
		setContentCommandText();
		setShowContentCommandPanel(true);
	};

	const handleSaveContentCommand = async () => {
		if (!contentCommandText || contentCommandText.trim() === '') {
			toast.error('Enter content command');
			return;
		}

		let tempSettingsData = { ...settingsData };

		if (!tempSettingsData.powerContentCommands) {
			tempSettingsData.powerContentCommands = [];
		}

		//check if content already exists
		if (!editingContentCommand) {
			if (
				tempSettingsData.powerContentCommands &&
				tempSettingsData.powerContentCommands.length > 0 &&
				tempSettingsData.powerContentCommands.some((x) => x.commandText === contentCommandText)
			) {
				toast.error('Content command already exists');
				return;
			}
		}

		let contentObj = {
			commandText : contentCommandText
		};

		if (editingContentCommand) {
			let tempEditingContent = tempSettingsData.powerContentCommands.find((x) => x === editingContentCommand);

			if (tempEditingContent) {
				let tempEditingContentGuid = tempEditingContent.commandGuid;

				//remove old Content first, keep guid
				tempSettingsData.powerContentCommands = tempSettingsData.powerContentCommands.filter(
					(x) => x.commandGuid !== tempEditingContent.commandGuid
				);

				tempSettingsData.powerContentCommands.push({
					...contentObj,
					commandGuid : tempEditingContentGuid
				});
			}
		} else {
			tempSettingsData.powerContentCommands.push({
				...contentObj,
				commandGuid : createGuid()
			});
		}

		await handleSaveDefaultSettings(tempSettingsData);
		await getDefaultSettings();

		setEditingContentCommand();
		setShowContentCommandPanel(false);
	};

	const handleEditContentCommand = (content) => {
		if (settingsData && settingsData.powerContentCommands) {
			let tempEditingContent = settingsData.powerContentCommands.find((x) => x === content);

			if (tempEditingContent) {
				setContentCommandText(tempEditingContent.commandText);

				setEditingContentCommand(content);
				setShowContentCommandPanel(true);
			}
		}
	};

	const handleCancelSaveContentCommand = () => {
		setEditingContentCommand();
		setShowContentCommandPanel(false);
	};

	const handleDeleteContentCommand = async (content) => {
		if (window.confirm('Are you sure that you want to delete content "' + content.commandText + '"?')) {
			if (settingsData && settingsData.powerContentCommands) {
				let tempSettingsData = { ...settingsData };

				let tempDeletingContent = tempSettingsData.powerContentCommands.find((x) => x === content);

				if (tempDeletingContent) {
					tempSettingsData.powerContentCommands = tempSettingsData.powerContentCommands.filter(
						(x) => x !== content
					);

					await handleSaveDefaultSettings(tempSettingsData);
					await getDefaultSettings();
				}
			}
		}
	};

	return (
		<div className="settings-dialog-wrapper">
			<ModalDialog position="right-sidebar">
				<ModalDialogContent align="start">
					{showWebsitePanel ? (
						<Fragment>
							<h4 className="mb-3">{`Add New Website`}</h4>

							<div className="row">
								<div className="col-12 mb-3">
									<label htmlFor="url" className="form-label">
										Url:
									</label>
									<input
										type="text"
										className="form-control"
										name="url"
										value={websiteUrl || ''}
										onChange={(e) => setWebsiteUrl(e.target.value)}
									/>
								</div>
								<div className="col-12 mb-1">
									<div className="form-check">
										<input
											type="checkbox"
											className="form-check-input"
											id="websiteIsCitations"
											name="websiteIsCitations"
											onChange={(e) => setWebsiteIsCitations(e.target.checked)}
											checked={websiteIsCitations}
										/>
										<label htmlFor="websiteIsCitations" className="form-check-label ms-2">
											Is Citations
										</label>
									</div>
								</div>
								<div className="col-12">
									<div className="form-check">
										<input
											type="checkbox"
											className="form-check-input"
											id="websiteIsContent"
											name="websiteIsContent"
											onChange={(e) => setWebsiteIsContent(e.target.checked)}
											checked={websiteIsContent}
										/>
										<label htmlFor="websiteIsContent" className="form-check-label ms-2">
											Is Content
										</label>
									</div>
								</div>
							</div>
						</Fragment>
					) : showCitationCommandPanel ? (
						<Fragment>
							<h4 className="mb-3">{`Add New Citation Command`}</h4>

							<div className="row">
								<div className="col-12 mb-4">
									<label htmlFor="citationCommandText" className="form-label">
										Command:
									</label>
									<input
										type="text"
										className="form-control"
										name="citationCommandText"
										value={citationCommandText || ''}
										onChange={(e) => setCitationCommandText(e.target.value)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<label className="form-label mb-2">Placeholders:</label>
									{citationsPlaceholders.map((ph, index) => (
										<div className="placeholder-item">
											<label className="form-label mb-0 me-2">{ph}</label>
											<button
												type="button"
												className="btn btn-link p-0"
												onClick={() => {
													copyToClipboard(ph);
												}}
												title={`Copy ${ph} to clipboard`}
											>
												<span>
													<i className="fa-regular fa-copy" />
												</span>
												{/* <span>{showShareLinkCopiedMessage ? 'Copied!' : '<i class="fa-regular fa-copy"></i>'}</span> */}
											</button>
										</div>
									))}
								</div>
							</div>
						</Fragment>
					) : showContentCommandPanel ? (
						<Fragment>
							<h4 className="mb-3">{`Add New Content Command`}</h4>

							<div className="row">
								<div className="col-12 mb-3">
									<label htmlFor="contentCommandText" className="form-label">
										Command:
									</label>
									<input
										type="text"
										className="form-control"
										name="contentCommandText"
										value={contentCommandText || ''}
										onChange={(e) => setContentCommandText(e.target.value)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<label className="form-label mb-2">Placeholders:</label>
									{contentPlaceholders.map((ph, index) => (
										<div className="placeholder-item">
											<label className="form-label mb-0 me-2">{ph}</label>
											<button
												type="button"
												className="btn btn-link p-0"
												onClick={() => {
													copyToClipboard(ph);
												}}
												title={`Copy ${ph} to clipboard`}
											>
												<span>
													<i className="fa-regular fa-copy" />
												</span>
												{/* <span>{showShareLinkCopiedMessage ? 'Copied!' : '<i class="fa-regular fa-copy"></i>'}</span> */}
											</button>
										</div>
									))}
								</div>
							</div>
						</Fragment>
					) : showCategoryPanel ? (
						<Fragment>
							<h4 className="mb-3">{`${editingCategory ? 'Edit' : 'Add New'} Category`}</h4>

							<div className="row">
								<div className="col-12 mb-3">
									<label htmlFor="categoryName" className="form-label">
										Name:
									</label>
									<input
										type="text"
										className="form-control"
										name="categoryName"
										value={categoryName || ''}
										onChange={(e) => setCategoryName(e.target.value)}
									/>
								</div>
								<div className="col-12">
									<div className="table-wrapper">
										<table className="items-list table table-responsive">
											<tbody>
												{settingsData &&
												settingsData.powerWebsites &&
												settingsData.powerWebsites.length > 0 && (
													<Fragment>
														{settingsData.powerWebsites.map((website, index) => {
															let websiteIsChecked =
																selectedWebsiteGuids &&
																selectedWebsiteGuids.includes(website.websiteGuid);

															return (
																<tr key={index}>
																	<td>
																		<div className="form-check col-6">
																			<input
																				type="checkbox"
																				className="form-check-input"
																				id={`chkWebsite_${website.websiteGuid}`}
																				name={`chkWebsite_${website.websiteGuid}`}
																				onChange={handleSettingsChange}
																				defaultChecked={websiteIsChecked}
																			/>
																			<label
																				htmlFor={`chkWebsite_${website.websiteGuid}`}
																				className="form-check-label ms-3"
																			>
																				{website.websiteUrl}
																			</label>
																		</div>
																	</td>
																</tr>
															);
														})}
													</Fragment>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<h4 className="mb-3">Default Settings</h4>

							<ul className="nav nav-tabs" id="tabSettings" role="tablist">
								<li className="nav-item" role="presentation" key="prompts">
									<button
										className={`nav-link ${currentPanel === 'prompts' ? 'active' : ''}`}
										type="button"
										onClick={() => setCurrentPanel('prompts')}
									>
										Content Prompts
									</button>
								</li>
								<li className="nav-item" role="presentation" key="reportSettings">
									<button
										className={`nav-link ${currentPanel === 'reportSettings' ? 'active' : ''}`}
										type="button"
										onClick={() => setCurrentPanel('reportSettings')}
									>
										Report Settings
									</button>
								</li>
							</ul>
							<div className="tab-content border mb-3" id="tabSettingsContent">
								{currentPanel === 'prompts' && (
									<div
										className={`tab-pane fade position-relative p-3 prompts ${currentPanel ===
										'prompts'
											? 'show active'
											: ''}`}
									>
										<div className="row">
											<div className="col-12 mb-3">
												<label htmlFor="headlineInstructionsAI" className="form-label">
													Headline Instructions:
												</label>
												<input
													type="text"
													className="form-control"
													name="headlineInstructionsAI"
													value={settingsData.headlineInstructionsAI || ''}
													onChange={handleSettingsChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="descriptionInstructionsAI" className="form-label">
													Description Instructions:
												</label>
												<textarea
													className="form-control"
													name="descriptionInstructionsAI"
													value={settingsData.descriptionInstructionsAI || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordsHeadlinePrompt" className="form-label">
													Keywords Headline Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordsHeadlinePrompt"
													value={settingsData.keywordsHeadlinePrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordsContentPrompt" className="form-label">
													Keywords Content Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordsContentPrompt"
													value={settingsData.keywordsContentPrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordPostHeadlinePrompt" className="form-label">
													Keywords Post Headline Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordPostHeadlinePrompt"
													value={settingsData.keywordPostHeadlinePrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordPostContentPrompt" className="form-label">
													Keywords Post Content Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordPostContentPrompt"
													value={settingsData.keywordPostContentPrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordGroupHeadlinePrompt" className="form-label">
													Keywords Group Headline Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordGroupHeadlinePrompt"
													value={settingsData.keywordGroupHeadlinePrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="locationContentPrompt" className="form-label">
													Keyword Group Location Prompt:
												</label>
												<textarea
													className="form-control"
													name="locationContentPrompt"
													value={settingsData.locationContentPrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
											<div className="col-12 mb-3">
												<label htmlFor="keywordItemsContentPrompt" className="form-label">
													Keyword Items Content Prompt:
												</label>
												<textarea
													className="form-control"
													name="keywordItemsContentPrompt"
													value={settingsData.keywordItemsContentPrompt || ''}
													onChange={handleSettingsChange}
													rows={3}
												/>
											</div>
										</div>
									</div>
								)}

								{currentPanel === 'reportSettings' && (
									<div
										className={`tab-pane fade position-relative p-3 ${currentPanel ===
										'reportSettings'
											? 'show active'
											: ''}`}
									>
										<ul className="nav nav-tabs" id="tabReportSettings" role="tablist">
											<li className="nav-item" role="presentation" key="powerWebsites">
												<button
													className={`nav-link ${currentReportSettingsPanel ===
													'powerWebsites'
														? 'active'
														: ''}`}
													type="button"
													onClick={() => setCurrentReportSettingsPanel('powerWebsites')}
												>
													Power Websites
												</button>
											</li>
											<li className="nav-item" role="presentation" key="industryCategories">
												<button
													className={`nav-link ${currentReportSettingsPanel ===
													'industryCategories'
														? 'active'
														: ''}`}
													type="button"
													onClick={() => setCurrentReportSettingsPanel('industryCategories')}
												>
													Industry Categories
												</button>
											</li>
											<li className="nav-item" role="presentation" key="powerCommands">
												<button
													className={`nav-link ${currentReportSettingsPanel ===
													'powerCommands'
														? 'active'
														: ''}`}
													type="button"
													onClick={() => setCurrentReportSettingsPanel('powerCommands')}
												>
													Commands
												</button>
											</li>
										</ul>
										<div className="tab-content border mb-3" id="tabReportSettingsContent">
											{currentReportSettingsPanel === 'powerWebsites' && (
												<div
													className={`tab-pane fade position-relative p-3 ${currentReportSettingsPanel ===
													'powerWebsites'
														? 'show active'
														: ''}`}
												>
													<div className="new-item-wrapper">
														<button
															className="btn btn-primary"
															onClick={handleAddNewWebsite}
														>
															Add New Website
														</button>
													</div>
													<div className="table-wrapper">
														<table className="items-list table border table-responsive">
															<thead>
																<tr>
																	<th className="url" scope="col">
																		Url
																	</th>
																	<th className="text-center" scope="col">
																		Is Citations
																	</th>
																	<th className="text-center" scope="col">
																		Is Content
																	</th>
																	<th scope="col" />
																</tr>
															</thead>
															<tbody>
																{settingsData &&
																settingsData.powerWebsites &&
																settingsData.powerWebsites.length > 0 && (
																	<Fragment>
																		{settingsData.powerWebsites.map(
																			(website, index) => {
																				return (
																					<tr key={index}>
																						<td className="url">
																							{website.websiteUrl}
																						</td>
																						<td className="text-center">
																							<input
																								type="checkbox"
																								className="form-check-input"
																								checked={
																									website.websiteIsCitations
																								}
																								readOnly
																							/>
																						</td>
																						<td className="text-center">
																							<input
																								type="checkbox"
																								className="form-check-input"
																								checked={
																									website.websiteIsContent
																								}
																								readOnly
																							/>
																						</td>

																						<td className="task-action text-end">
																							<button
																								className="btn btn-link py-0 px-1"
																								onClick={() =>
																									handleEditWebsite(
																										website
																									)}
																								title="Edit Website"
																							>
																								<i className="fa-solid fa-pen" />
																							</button>
																							<button
																								className="btn btn-link py-0 px-1"
																								onClick={() =>
																									handleDeleteWebsite(
																										website
																									)}
																								title="Delete Website"
																							>
																								<i className="fa-solid fa-trash-can" />
																							</button>
																						</td>
																					</tr>
																				);
																			}
																		)}
																	</Fragment>
																)}
															</tbody>
														</table>
													</div>
												</div>
											)}

											{currentReportSettingsPanel === 'industryCategories' && (
												<div
													className={`tab-pane fade position-relative p-3 ${currentReportSettingsPanel ===
													'industryCategories'
														? 'show active'
														: ''}`}
												>
													<div className="new-item-wrapper">
														<button
															className="btn btn-primary"
															onClick={handleAddNewCategory}
														>
															Add New Category
														</button>
													</div>
													<div className="table-wrapper">
														<table className="items-list table border table-responsive">
															<thead>
																<tr>
																	<th className="category" scope="col">
																		Name
																	</th>
																	<th className="citations" scope="col">
																		Citations Websites
																	</th>
																	<th className="content" scope="col">
																		Content Websites
																	</th>
																	<th scope="col" />
																</tr>
															</thead>
															<tbody>
																{settingsData &&
																settingsData.industryCategories &&
																settingsData.industryCategories.length > 0 && (
																	<Fragment>
																		{settingsData.industryCategories.map(
																			(cat, index) => {
																				let citationsStr = '';
																				let contentStr = '';

																				if (
																					settingsData.powerWebsites &&
																					cat.websites &&
																					cat.websites.length > 0
																				) {
																					cat.websites.forEach((guid) => {
																						let website = settingsData.powerWebsites.find(
																							(x) =>
																								x.websiteGuid === guid
																						);

																						if (website) {
																							if (
																								website.websiteIsCitations
																							) {
																								citationsStr +=
																									website.websiteUrl
																										.replace(
																											'https://',
																											''
																										)
																										.replace(
																											'www.',
																											''
																										)
																										.replace(
																											'.com',
																											''
																										) + ', ';
																							}

																							if (
																								website.websiteIsContent
																							) {
																								contentStr +=
																									website.websiteUrl
																										.replace(
																											'https://',
																											''
																										)
																										.replace(
																											'www.',
																											''
																										)
																										.replace(
																											'.com',
																											''
																										) + ', ';
																							}
																						}
																					});

																					if (
																						citationsStr &&
																						citationsStr.length > 2
																					) {
																						citationsStr = citationsStr.substring(
																							0,
																							citationsStr.length - 2
																						);
																					}

																					if (
																						contentStr &&
																						contentStr.length > 2
																					) {
																						contentStr = contentStr.substring(
																							0,
																							contentStr.length - 2
																						);
																					}
																				}

																				return (
																					<tr key={index}>
																						<td className="category">
																							{cat.categoryName}
																						</td>
																						<td
																							className="citations text-truncate"
																							title={citationsStr}
																						>
																							{citationsStr}
																						</td>
																						<td
																							className="content text-truncate"
																							title={contentStr}
																						>
																							{contentStr}
																						</td>

																						<td className="task-action text-end">
																							<button
																								className="btn btn-link py-0 px-1"
																								onClick={() =>
																									handleEditCategory(
																										cat
																									)}
																								title="Edit Category"
																							>
																								<i className="fa-solid fa-pen" />
																							</button>
																							<button
																								className="btn btn-link py-0 px-1"
																								onClick={() =>
																									handleDeleteCategory(
																										cat
																									)}
																								title="Delete Category"
																							>
																								<i className="fa-solid fa-trash-can" />
																							</button>
																						</td>
																					</tr>
																				);
																			}
																		)}
																	</Fragment>
																)}
															</tbody>
														</table>
													</div>
												</div>
											)}

											{currentReportSettingsPanel === 'powerCommands' && (
												<div
													className={`tab-pane fade position-relative p-3 ${currentReportSettingsPanel ===
													'powerCommands'
														? 'show active'
														: ''}`}
												>
													<ul className="nav nav-tabs" id="tabPowerCommands" role="tablist">
														<li
															className="nav-item"
															role="presentation"
															key="powerCitationsCommands"
														>
															<button
																className={`nav-link ${currentPowerCommandsPanel ===
																'powerCitationsCommands'
																	? 'active'
																	: ''}`}
																type="button"
																onClick={() =>
																	setCurrentPowerCommandsPanel(
																		'powerCitationsCommands'
																	)}
															>
																Citations
															</button>
														</li>
														<li
															className="nav-item"
															role="presentation"
															key="powerContentCommands"
														>
															<button
																className={`nav-link ${currentPowerCommandsPanel ===
																'powerContentCommands'
																	? 'active'
																	: ''}`}
																type="button"
																onClick={() =>
																	setCurrentPowerCommandsPanel(
																		'powerContentCommands'
																	)}
															>
																Content
															</button>
														</li>
													</ul>
													<div
														className="tab-content border mb-3"
														id="tabPowerCommandsContent"
													>
														<div
															className={`tab-pane fade position-relative p-3 ${currentPowerCommandsPanel ===
															'powerCitationsCommands'
																? 'show active'
																: ''}`}
														>
															<div className="new-item-wrapper">
																<button
																	className="btn btn-primary"
																	onClick={handleAddNewCitationCommand}
																>
																	Add New Citation Command
																</button>
															</div>
															<div className="table-wrapper">
																<table className="items-list table border table-responsive">
																	<thead>
																		<tr>
																			<th className="url" scope="col">
																				Citation Command
																			</th>
																			<th scope="col" />
																		</tr>
																	</thead>
																	<tbody>
																		{settingsData &&
																		settingsData.powerCitationsCommands &&
																		settingsData.powerCitationsCommands.length >
																			0 && (
																			<Fragment>
																				{settingsData.powerCitationsCommands.map(
																					(command, index) => {
																						return (
																							<tr key={index}>
																								<td className="url">
																									{
																										command.commandText
																									}
																								</td>

																								<td className="task-action text-end">
																									<button
																										className="btn btn-link py-0 px-1"
																										onClick={() =>
																											handleEditCitationCommand(
																												command
																											)}
																										title="Edit Citation Command"
																									>
																										<i className="fa-solid fa-pen" />
																									</button>
																									<button
																										className="btn btn-link py-0 px-1"
																										onClick={() =>
																											handleDeleteCitationCommand(
																												command
																											)}
																										title="Delete Citation Command"
																									>
																										<i className="fa-solid fa-trash-can" />
																									</button>
																								</td>
																							</tr>
																						);
																					}
																				)}
																			</Fragment>
																		)}
																	</tbody>
																</table>
															</div>
														</div>
														<div
															className={`tab-pane fade position-relative p-3 ${currentPowerCommandsPanel ===
															'powerContentCommands'
																? 'show active'
																: ''}`}
														>
															<div className="new-item-wrapper">
																<button
																	className="btn btn-primary"
																	onClick={handleAddNewContentCommand}
																>
																	Add New Content Command
																</button>
															</div>
															<div className="table-wrapper">
																<table className="items-list table border table-responsive">
																	<thead>
																		<tr>
																			<th className="url" scope="col">
																				Content Command
																			</th>
																			<th scope="col" />
																		</tr>
																	</thead>
																	<tbody>
																		{settingsData &&
																		settingsData.powerContentCommands &&
																		settingsData.powerContentCommands.length >
																			0 && (
																			<Fragment>
																				{settingsData.powerContentCommands.map(
																					(command, index) => {
																						return (
																							<tr key={index}>
																								<td className="url">
																									{
																										command.commandText
																									}
																								</td>

																								<td className="task-action text-end">
																									<button
																										className="btn btn-link py-0 px-1"
																										onClick={() =>
																											handleEditContentCommand(
																												command
																											)}
																										title="Edit Content Command"
																									>
																										<i className="fa-solid fa-pen" />
																									</button>
																									<button
																										className="btn btn-link py-0 px-1"
																										onClick={() =>
																											handleDeleteContentCommand(
																												command
																											)}
																										title="Delete Content Command"
																									>
																										<i className="fa-solid fa-trash-can" />
																									</button>
																								</td>
																							</tr>
																						);
																					}
																				)}
																			</Fragment>
																		)}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
							</div>

							<div className="row">
								<div className="col-12 mb-2">
									<label htmlFor="autoReport" className="form-check-label">
										Auto Report:
									</label>
									<input
										type="checkbox"
										className="form-check-input ms-3"
										name="autoReport"
										checked={settingsData.autoReport}
										onChange={handleSettingsChange}
									/>
								</div>
								<div className="col-12">
									<label htmlFor="autoMergeForward" className="form-check-label">
										Auto Merge Forward 30 Day Report:
									</label>
									<input
										type="checkbox"
										className="form-check-input ms-3"
										name="autoMergeForward"
										checked={settingsData.autoMergeForward}
										onChange={handleSettingsChange}
									/>
								</div>
							</div>
						</Fragment>
					)}
				</ModalDialogContent>
				<ModalDialogButtons>
					<button
						className="btn btn-primary"
						onClick={
							showWebsitePanel ? (
								handleSaveWebsite
							) : showCitationCommandPanel ? (
								handleSaveCitationCommand
							) : showContentCommandPanel ? (
								handleSaveContentCommand
							) : showCategoryPanel ? (
								handleSaveCategory
							) : (
								() => handleSaveDefaultSettings(settingsData, true)
							)
						}
					>
						<span>
							{showWebsitePanel ? (
								'Save Website'
							) : showCitationCommandPanel ? (
								'Save Citation Command'
							) : showContentCommandPanel ? (
								'Save Content Command'
							) : showCategoryPanel ? (
								'Save Category'
							) : (
								'Save Settings'
							)}
						</span>
					</button>
					<button
						className="btn btn-outline-primary"
						onClick={
							showWebsitePanel ? (
								handleCancelSaveWebsite
							) : showCitationCommandPanel ? (
								handleCancelSaveCitationCommand
							) : showContentCommandPanel ? (
								handleCancelSaveContentCommand
							) : showCategoryPanel ? (
								handleCancelSaveCategory
							) : (
								handleCloseDialog
							)
						}
					>
						<span>
							{showWebsitePanel ? (
								'Cancel'
							) : showCitationCommandPanel ? (
								'Cancel'
							) : showContentCommandPanel ? (
								'Cancel'
							) : showCategoryPanel ? (
								'Cancel'
							) : (
								'Close'
							)}
						</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</div>
	);
};

export default SettingsDialog;
