import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import './PageHeadline.css';
import UserProfileInfo from '../administration/userProfileInfo/UserProfileInfo';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetLoginAsToken } from '../../Services';
import { setToken } from '../../utils/StorageUtil';

const PageHeadline = ({
	campaign,
	headline,
	linkText = 'Go Back',
	linkUrl = '',
	className = '',
	buttonsHtml = null,
	showUserInfo = true
}) => {
	const [ { user, adminUser, host }, dispatch ] = useStateValue();
	const [ isNoticeVisible, setIsNoticeVisible ] = useState(false);
	const [ isDevVersion, setIsDevVersion ] = useState(false);
	const navigate = useNavigate();

	useEffect(
		() => {
			if (user && adminUser) {
				if (user.id !== adminUser.id) {
					setIsNoticeVisible(true);
				} else {
					setIsNoticeVisible(false);
				}
			} else {
				setIsNoticeVisible(false);
			}
		},
		[ user, adminUser ]
	);

	useEffect(
		() => {
			if (host && (host.indexOf('dev.') > -1 || host.indexOf('dev-') > -1)) {
				setIsDevVersion(true);
			} else {
				setIsDevVersion(false);
			}
		},
		[ host ]
	);

	const exitLoginAs = async () => {
		if (adminUser) {
			let data = { id: adminUser.id };

			//get new user token
			const adminUserTokenResponse = await GetLoginAsToken(data);

			if (adminUserTokenResponse) {
				setToken(adminUserTokenResponse);

				dispatch({
					type : actionTypes.SET_USER,
					user : adminUser
				});

				navigate('/admin');
			}
		}
	};

	return (
		<div className={`page-header-container ${className ? className : ''}`}>
			<div className="headline-wrapper">
				<div className="d-flex">
					{isDevVersion && (
						<span className="px-3 py-2 mb-1 me-3 bg-danger text-white rounded">
							<h5>DEV VERSION</h5>
						</span>
					)}

					{linkUrl && (
						<button className="btn btn-outline-primary me-4" onClick={() => navigate(linkUrl)}>
							<i className="fa-solid fa-arrow-left-long" />
							{linkText}
						</button>
					)}
					<h4 className="align-self-center">{headline}</h4>

					{campaign && (
						<Fragment>
							{(campaign.status === 'Archived' ||
								campaign.status === 'Canceled' ||
								campaign.status === 'Deleted') && (
								<span className="px-3 py-2 mb-1 ms-3 bg-danger text-white rounded">
									<h5>{campaign.status.toUpperCase()}</h5>
								</span>
							)}

							<div className="page-headline-links-wrapper">
								<a
									target="_blank"
									className="btn btn-link page-headline-link"
									href={campaign.website}
									title="Website Url"
								>
									<i className="fa-solid fa-globe" />
								</a>
								<a
									target="_blank"
									className="btn btn-link page-headline-link"
									href={
										campaign.url && campaign.url.indexOf('http') > -1 ? (
											campaign.url
										) : (
											`https://${campaign.url}`
										)
									}
									title="Directory Url"
								>
									<i className="fa-solid fa-map-location-dot" />
								</a>
								<a
									target="_blank"
									className="btn btn-link page-headline-link"
									href={campaign.googleCid && `https://google.com/maps/?cid=${campaign.googleCid}`}
									title="Google Profile"
								>
									<i className="fa-brands fa-google" />
								</a>
							</div>
						</Fragment>
					)}
				</div>
			</div>

			{isNoticeVisible && (
				<div className="logged-in-wrapper ms-5">
					<span>Logged in as {user.email}</span>
					<button className="btn btn-link p-0 ms-3" onClick={exitLoginAs}>
						EXIT
					</button>
				</div>
			)}

			{buttonsHtml && <Fragment>{buttonsHtml}</Fragment>}

			{user && showUserInfo && <UserProfileInfo />}
		</div>
	);
};

export default PageHeadline;
